import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'

const OldValue = ({ children }) => {
  return <span style={{ textDecoration: 'line-through' }}>{children}</span>
}

const DiffCard = ({
  title,
  emptyMsg,
  result,
  color,
}: {
  title: string
  emptyMsg: string
  result: any
  color: string
}) => {
  return (
    <Card>
      <CardHeader>
        <i className="fa fa-align-justify"></i>
        <strong>{title}</strong>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            {Object.keys(result).length === 0 ? emptyMsg : ''}

            {result.map((row: { name: string; fields: any[] }, i: number) => {
              const { name, fields } = row

              return (
                <div className={`alert alert-${color} fade show`} key={i}>
                  <b>{name}</b>
                  <ul>
                    {fields.map((fieldData, i) => {
                      if (fieldData.hasPrevious === false) {
                        return (
                          <li key={i}>
                            <b>{String(fieldData.field).toUpperCase()}:</b>{' '}
                            {fieldData.new}
                            <br />
                          </li>
                        )
                      }

                      return (
                        <li key={fieldData.field}>
                          <b>{String(fieldData.field).toUpperCase()}:</b>{' '}
                          <OldValue>{fieldData.previous}</OldValue> ➡
                          {` ${fieldData.new}`}
                          <br />
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )
            })}
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

const DiffView = ({ scope, result }) => {
  return (
    <>
      {result.errors.length ? (
        <Row>
          <Col>
            {result.errors.map(
              (error: { displayMessage: string }, i: number) => {
                const { displayMessage } = error

                return (
                  <div className="alert alert-danger fade show" key={i}>
                    {displayMessage}
                  </div>
                )
              },
            )}
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col>
          <DiffCard
            title={`New ${scope}`}
            emptyMsg={`No ${scope} to add.`}
            {...{ scope, result: result.create }}
            color="info"
          />
        </Col>
        <Col>
          <DiffCard
            title={`Modified ${scope}`}
            emptyMsg={`No modified ${scope}`}
            color="warning"
            {...{ scope, result: result.update }}
          />
        </Col>
      </Row>
    </>
  )
}

export default DiffView
