import { TagsCell } from 'components'
import ProductStatusBadge from 'components/Badges/ProductStatusBadge'
import { formatPrice } from 'utils/price'

import type { ColumnDef } from 'components/Table/types'
import DiscountBadge from 'components/Badges/DiscountBadge'

const renderPriceValue = (value: string) => {
  return value && value !== '0.00' ? `$${formatPrice(Number(value) * 100)}` : ''
}

const columnDef: ColumnDef[] = [
  {
    label: '',
    id: 'checkbox',
    sortColumn: false,
    canExport: false,
  },
  {
    label: 'ID',
    id: 'id',
    sortColumn: true,
    defaultVisible: false,
  },
  {
    label: 'Parent ID',
    id: 'parentProductId',
    sortColumn: true,
    defaultVisible: false,
  },
  {
    label: 'Status',
    id: 'status',
    sortColumn: false,
    defaultVisible: false,
    renderer: ({ value }) => {
      return (
        <td>
          <ProductStatusBadge value={String(value)} />
        </td>
      )
    },
  },
  {
    label: 'Name',
    id: 'name',
    sortColumn: true,
  },
  {
    label: 'Enabled',
    id: 'enabled',
    sortColumn: false,
    defaultVisible: false,
  },
  {
    label: 'Botanical name',
    id: 'botanicalName',
    defaultVisible: false,
    sortColumn: true,
  },
  {
    label: 'Growing Zone',
    id: 'pa_growing-zone',
    sortColumn: false,
    defaultVisible: false,
  },
  {
    label: 'Mature Height',
    id: 'matureWidth',
    sortColumn: true,
    defaultVisible: false,
  },
  {
    label: 'Mature Width',
    id: 'matureHeight',
    sortColumn: true,
    defaultVisible: false,
  },
  {
    label: 'Tags',
    id: 'tags',
    sortColumn: false,
    renderer: ({ value }) => (value ? <TagsCell {...{ value }} /> : <td />),
    canExport: false,
  },
  {
    label: 'Size',
    id: 'size',
    sortColumn: true,
  },
  {
    label: 'Var Desc',
    id: 'variationDescription',
    sortColumn: true,
    defaultVisible: false,
  },
  {
    label: 'Qty Sold',
    id: 'qtySold',
    title:
      'Total line item quantity for orders within the selected date range.',
    sortColumn: true,
    isNumeric: true,
    defaultVisible: false,
  },
  {
    label: 'Revenue',
    id: 'revenue',
    title: 'Total line item revenue for orders within the selected date range.',
    sortColumn: true,
    isNumeric: true,
    defaultVisible: false,
    renderValue: renderPriceValue,
  },
  {
    label: 'SKU',
    id: 'sku',
    sortColumn: true,
    hasClipboardButton: true,
  },
  {
    label: 'GRND',
    id: 'numGround',
    sortColumn: true,
    isNumeric: true,
  },
  {
    label: 'TO_PULL',
    id: 'numAwaitingPull',
    sortColumn: true,
    isNumeric: true,
  },
  {
    label: 'WEB',
    id: 'numWeb',
    sortColumn: true,
    isNumeric: true,
  },
  {
    label: 'SHOULD WEB',
    id: 'availableQty',
    sortColumn: true,
    isNumeric: true,
  },
  {
    label: 'DIFF WEB',
    id: 'diffWeb',
    sortColumn: true,
    isNumeric: true,
  },
  {
    label: 'ORDRD',
    id: 'numOrdered',
    sortColumn: true,
    isNumeric: true,
  },
  {
    label: 'PRELISTED',
    id: 'numPrelisted',
    sortColumn: true,
    isNumeric: true,
  },
  {
    label: 'IN_BATCH',
    id: 'numInBatch',
    sortColumn: true,
    isNumeric: true,
  },
  {
    label: 'IDEAL',
    id: 'numIdeal',
    sortColumn: true,
    isEditable: true,
    isNumeric: true,
  },
  {
    label: 'NEED',
    id: 'numNeed',
    sortColumn: true,
    isNumeric: true,
  },
  {
    label: 'Price',
    id: 'regularPrice',
    sortColumn: true,
    isNumeric: true,
    isEditable: true,
    defaultVisible: false,
    renderValue: renderPriceValue,
  },
  {
    label: 'Sale Price',
    id: 'salePrice',
    sortColumn: true,
    isNumeric: true,
    isEditable: true,
    defaultVisible: false,
    renderValue: renderPriceValue,
  },
  {
    label: 'COGS',
    id: 'costOfGoods',
    sortColumn: true,
    defaultVisible: false,
    isEditable: false,
    renderValue: renderPriceValue,
  },
  {
    label: 'PRICE/COGS',
    id: 'pricePerCogs',
    sortColumn: true,
    defaultVisible: false,
    isEditable: false,
    renderValue: renderPriceValue,
  },
  {
    label: 'In Stock %',
    id: 'inStockRate',
    sortColumn: true,
    defaultVisible: false,
    title:
      'Percentage of time the inventory was != 0 for the selected date range.',
  },
  {
    label: 'Planning',
    id: 'planning',
    sortColumn: false,
    defaultVisible: false,
    isNumeric: true,
    isEditable: true,
  },
  {
    label: 'Discount',
    id: 'discount',
    sortColumn: false,
    defaultVisible: false,
    renderer: ({ value, row }) => {
      return (
        <td>
          <DiscountBadge
            {...{ value }}
            isDisabled={row.discountStatus === 'INACTIVE'}
          />
        </td>
      )
    },
  },
]

export default columnDef
